import { Battery } from '@36node-mekong/sdk-ts';
import { Badge } from 'antd';
import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { API_CACHE_TIME } from 'src/config';
import { useSlice } from 'src/lib/redux-toolkit';

import { allBatterySlice, batteryByIdSlice } from './slice';

export interface BatteryProviderProps {
  id: string;
  children: (battery: Battery | undefined) => JSX.Element | null;
}

export interface BatteryListProps {
  ids: string[];
  children: (batteryList: Battery[]) => JSX.Element;
}

export const useBattery = (batteryId: string): Battery | undefined => {
  const dispatch = useDispatch();
  const [{ result, successAt }, getBatteryById] = useSlice(batteryByIdSlice, { key: batteryId });
  const shouldFetch = !successAt || moment(successAt) > moment().add(API_CACHE_TIME, 'ms');

  useEffect(() => {
    if (shouldFetch && batteryId) {
      dispatch(getBatteryById.request({ battery: batteryId }));
    }
  }, [batteryId]);

  return result;
};

export const useAllBatteries = (): Battery[] => {
  const dispatch = useDispatch();
  const [{ result = [], successAt }, listBatteries] = useSlice(allBatterySlice);
  const shouldFetch = !successAt || moment(successAt) > moment().add(API_CACHE_TIME, 'ms');

  useEffect(() => {
    if (shouldFetch) {
      dispatch(listBatteries.request({ _limit: 10000 }));
    }
  }, []); // eslint-disable-line

  return result;
};

export const BatteryProvider = ({ id, children }: BatteryProviderProps) => {
  const result = useBattery(id);
  return children(result);
};

type BatteryStatusUiObj = {
  label: string;
  color: string;
};

export const batteryStatusUiMap: { [key: string]: BatteryStatusUiObj } = {
  未接入: { label: '未接入', color: 'grey' },
  离线: { label: '离线', color: 'grey' },
  在线: { label: '在线', color: 'green' },
};

export function BatteryStateBadge({ status }: { status: string }) {
  const found = batteryStatusUiMap[status];
  if (!found) return null;
  return <Badge color={found.color} text={found.label} />;
}
