import mqtt from 'mqtt/dist/mqtt';
import { useContext, useEffect } from 'react';

import { MqttContext } from './context';

export type MqttHandler = (topic: string, payload: any) => void;
export interface UseMqttOptions {
  topic?: string;
  handler?: MqttHandler;
}

export function useMqtt(options: UseMqttOptions = {}): mqtt.MqttClient | null {
  const { topic, handler } = options;
  const client = useContext(MqttContext);

  useEffect(() => {
    let realHandler: mqtt.OnMessageCallback;
    let isSubscribed = false;

    if (!client) {
      return;
    }

    if (topic) {
      client.subscribe(topic, (err) => {
        if (!err) {
          isSubscribed = true;
        }
      });
    }

    // 这里写的不够，handler 会被所有 topic 触发
    // 应当在此处，可以根据 topic 进行过滤
    if (handler) {
      realHandler = (topic, message) => {
        const raw = message.toString();
        try {
          const payload = JSON.parse(raw);
          handler(topic, payload);
        } catch (err) {
          handler(topic, raw);
        }
      };
      client.on('message', realHandler);
    }

    return () => {
      if (handler) {
        client.removeListener('message', realHandler);
      }
      if (isSubscribed && topic) {
        client.unsubscribe(topic);
      }
    };
  }, [client, topic, handler]);

  return client;
}
