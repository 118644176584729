import { FaultRecordStatus } from '@36node-mekong/sdk-ts';
import { Badge, Select, SelectProps } from 'antd';
import styled from 'styled-components';

import { Format } from 'src/components/format';

interface FaultLevelSpanProps {
  level: number | undefined;
}

export const faultRecordStateUiMap = {
  [FaultRecordStatus.BROKEN]: { label: '未恢复', color: 'yellow' },
  [FaultRecordStatus.RECOVER]: { label: '已恢复', color: 'green' },
};

type FaultLevelUiObj = {
  label: string;
  s: string;
  color: string;
  value: number;
};

type Fault = {
  name: string;
  code: string;
  level?: number;
  type: string;
};

export const FaultLevelUiMap: { [key: number]: FaultLevelUiObj } = {
  1: { label: '一级', s: '一', color: 'ffd478', value: 1 },
  2: { label: '二级', s: '二', color: 'ffae5f', value: 2 },
  3: { label: '三级', s: '三', color: 'ff5227', value: 3 },
};
export const toFaultLevelStr = (level?: number) => (level ? FaultLevelUiMap[level]?.s : '--');

const StyledDiv = styled.div`
  width: 24px;
  height: 24px;
  color: #fff;
  text-align: center;
  font-size: 14px;
  background-color: #${(props: FaultLevelSpanProps) => FaultLevelUiMap[props.level as number]?.color};
`;

export function FaultLevelGlob({ level }: FaultLevelSpanProps) {
  return (
    <Format value={level} formatter={toFaultLevelStr}>
      {(v) => <StyledDiv level={level}>{v}</StyledDiv>}
    </Format>
  );
}

export function getFaultRecordStateLabel(status: FaultRecordStatus) {
  return faultRecordStateUiMap[status]?.label;
}

export function FaultRecordStateBadge({ status }: { status: FaultRecordStatus }) {
  const found = faultRecordStateUiMap[status];
  if (!found) return null;
  return <Badge color={found.color} text={found.label} />;
}

export function FaultRecordStateSelect(props: SelectProps) {
  return (
    <Select {...props}>
      {Object.entries(faultRecordStateUiMap).map(([key, obj]) => (
        <Select.Option key={key} value={key}>
          {obj.label}
        </Select.Option>
      ))}
    </Select>
  );
}

export function FaultLevelSelect(props: SelectProps) {
  return (
    <Select {...props}>
      {Object.values(FaultLevelUiMap).map((l) => (
        <Select.Option key={l.value} value={l.value}>
          {l.label}
        </Select.Option>
      ))}
    </Select>
  );
}

export const WC = {
  BBox: {
    TIME_WRONG: 'BBOX_TIME_WRONG',
    GPS_WRONG: 'BBOX_GPS_WRONG',
    VIN_WRONG: 'BBOX_VIN_WRONG',
    BOX_CODE_WRONG: 'BBOX_BOX_CODE_WRONG',
  },
};

// warning types
export enum WT {
  BBOX = 'BBOX_WARNING',
}

const Warnings: Fault[] = [
  /**
   * warning
   */
  { name: '时间错误', code: WC.BBox.TIME_WRONG, type: WT.BBOX },
  { name: 'GPS异常', code: WC.BBox.GPS_WRONG, type: WT.BBOX },
  { name: 'VIN异常', code: WC.BBox.VIN_WRONG, type: WT.BBOX },
  { name: '电池编号异常', code: WC.BBox.BOX_CODE_WRONG, type: WT.BBOX },
];

export function WarningSelect(props: SelectProps) {
  return (
    <Select {...props}>
      {Warnings.map((l) => (
        <Select.Option key={l.code} value={l.code}>
          {l.name}
        </Select.Option>
      ))}
    </Select>
  );
}
