import { ConfigProvider, Spin } from 'antd';
import zhCN from 'antd/lib/locale-provider/zh_CN';

import 'moment';
import 'moment-duration-format';
import 'moment/locale/zh-cn';

import { Suspense } from 'react';

import './app.less';

import { SessionProvider } from './features';
import { RoutesRenderer } from './routes';

function App() {
  return (
    <Suspense fallback={<Spin />}>
      <ConfigProvider locale={zhCN}>
        <SessionProvider>
          <RoutesRenderer />
        </SessionProvider>
      </ConfigProvider>
    </Suspense>
  );
}

export default App;
