import * as mekong from '@36node-mekong/sdk-ts';
import { Badge } from 'antd';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { makeEntitiesSelector } from 'src/lib/redux-toolkit';
import { chargerSchema, robotSchema } from 'src/services';

export const useChargerList = (ids: string[]): mekong.Charger[] => {
  const select = useMemo(() => makeEntitiesSelector([chargerSchema]), []);
  const chargerList = useSelector((state: any) => select(state, ids));
  return chargerList;
};

export const useRobotList = (ids: string[]): mekong.Robot[] => {
  const select = useMemo(() => makeEntitiesSelector([robotSchema]), []);
  const robotList = useSelector((state: any) => select(state, ids));
  return robotList;
};

// 电池仓状态
const CabinetStateUiMap = {
  [mekong.CabinetStatus.NORMAL]: { label: '无', color: '#d9d9d9' },
  [mekong.CabinetStatus.REPAIR]: { label: '检修', color: 'red' },
  [mekong.CabinetStatus.OFFLINE]: { label: '正常', color: 'green' },
  [mekong.CabinetStatus.UNKNOWN]: { label: '未知', color: 'grey' },
};

// 充电机状态
export const ChargerStateUiMap = {
  [mekong.ChargerStatus.STANDBY]: { label: '待机', color: '#13c2c2' },
  [mekong.ChargerStatus.CHARGING]: { label: '充电中', color: '#1890ff' },
  [mekong.ChargerStatus.FULLY]: { label: '充电完成', color: '#52c41a' },
  [mekong.ChargerStatus.ERROR]: { label: '故障', color: '#f5222d' },
  [mekong.ChargerStatus.OFFLINE]: { label: '离线', color: '#d9d9d9' },
  [mekong.ChargerStatus.UNKNOWN]: { label: '未知', color: 'grey' },
};

// 机器人状态
export const RobotStateUiMap = {
  [mekong.RobotStatus.STANDBY]: { label: '待机', color: '#13c2c2' },
  [mekong.RobotStatus.WORKING]: { label: '运行', color: '#1890ff' },
  [mekong.RobotStatus.ERROR]: { label: '故障', color: '#f5222d' },
  [mekong.RobotStatus.OFFLINE]: { label: '离线', color: '#d9d9d9' },
  [mekong.RobotStatus.UNKNOWN]: { label: '未知', color: 'grey' },
};

const RobotTypeLabelMap = {
  [mekong.RobotType.GANTRY]: { label: '桁架式' },
  [mekong.RobotType.ARM]: { label: '吊臂式' },
  [mekong.RobotType.SIDE]: { label: '侧换式' },
};

// 模块类型
export const ModuleTypeLabelMap = {
  [mekong.ModuleType.KW_15]: { label: '15kW' },
  [mekong.ModuleType.KW_20]: { label: '20kW' },
  [mekong.ModuleType.KW_30]: { label: '30kW' },
};

// 设备类型
export const DeviceTypeUiMap = {
  [mekong.DeviceType.CHARGER]: { label: '充电机' },
  [mekong.DeviceType.ROBOT]: { label: '机器人' },
  [mekong.DeviceType.BATTERY]: { label: '电池' },
  [mekong.DeviceType.STATION]: { label: '充换电站' },
  [mekong.DeviceType.VEHICLE]: { label: '车辆' },
};

export const getCabinetStateLabel = (status: mekong.CabinetStatus) => CabinetStateUiMap[status]?.label;
export const getChargerStateLabel = (status: mekong.ChargerStatus) => ChargerStateUiMap[status]?.label;
export const getRobotStateLabel = (status: mekong.RobotStatus) => RobotStateUiMap[status]?.label;
export const getRobotTypeLabel = (type: mekong.RobotType) => RobotTypeLabelMap[type]?.label;
export const getModuleTypeLabel = (type: mekong.ModuleType) => ModuleTypeLabelMap[type]?.label;
export const getDeviceTypeLabel = (type: mekong.DeviceType) => DeviceTypeUiMap[type]?.label;

export function CabinetStateBadge({ status }: { status: mekong.CabinetStatus }) {
  const found = CabinetStateUiMap[status];
  if (!found) return null;
  return <Badge color={found.color} text={found.label} />;
}

export function ChargerStateBadge({ status }: { status: mekong.ChargerStatus }) {
  const found = ChargerStateUiMap[status];
  if (!found) return null;
  return <Badge color={found.color} text={found.label} />;
}

export function RobotStateBadge({ status }: { status: mekong.RobotStatus }) {
  const found = RobotStateUiMap[status];
  if (!found) return null;
  return <Badge color={found.color} text={found.label} />;
}
