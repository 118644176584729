import { ListStationsCountableField, Station, StationMode, StationState, StationStatus } from '@36node-mekong/sdk-ts';
import { Badge, Select, SelectProps } from 'antd';
import moment from 'moment';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { API_CACHE_TIME, MQTT_CONSUMER_GROUP } from 'src/config';
import { useMqtt } from 'src/lib/mqtt';
import { useSlice } from 'src/lib/redux-toolkit';

import {
  allStationsSlice,
  ChargerMeasure,
  chargerMeasureSlice,
  globalSlice,
  stationByIdSlice,
  stationByNSSlice,
  stationStateByNoSlice,
  stationStateSlice,
} from './slice';

export interface StationProviderProps {
  id: string | undefined;
  children: (stations: Station | undefined) => JSX.Element;
}

export const StationStateUiMap = {
  [StationStatus.UNKNOWN]: { label: '未知', value: StationStatus.UNKNOWN, color: 'gray' },
  [StationStatus.OPEN]: { label: '营运中', value: StationStatus.OPEN, color: 'green' },
  [StationStatus.CLOSED]: { label: '停运中', value: StationStatus.CLOSED, color: '#d9d9d9' },
  [StationStatus.MAINTAINING]: { label: '停运中', value: StationStatus.MAINTAINING, color: 'gray' },
  [StationStatus.UNDER_CONSTRUCTION]: { label: '停运中', value: StationStatus.UNDER_CONSTRUCTION, color: 'gray' },
};

export const StationModeUiMap = {
  [StationMode.RUNNING]: { label: '运营' },
  [StationMode.TESTING]: { label: '测试' },
  [StationMode.MAINTENANCE]: { label: '检修' },
};

export const getStationStateLabel = (val: StationStatus) => StationStateUiMap[val]?.label;
export const getStationModeLabel = (val: StationMode) => StationModeUiMap[val]?.label;

export const useStation = (id: string | undefined): Station | undefined => {
  const dispatch = useDispatch();
  const [{ result, successAt }, getStationById] = useSlice(stationByIdSlice, { key: id });
  const shouldFetch = !successAt || moment(successAt) > moment().add(API_CACHE_TIME, 'ms');

  useEffect(() => {
    if (shouldFetch && id) {
      dispatch(getStationById.request({ station: id }));
    }
  }, [id]);

  return result;
};

export const useStationStateByNo = (no: string | undefined): StationState | undefined => {
  const dispatch = useDispatch();
  const [{ result }, getStationStateByNo] = useSlice(stationStateByNoSlice, { key: no });

  useEffect(() => {
    if (no) {
      dispatch(getStationStateByNo.request({ stationNo: no }));
    }
  }, [no]);

  return result;
};

export const StationProvider = ({ id, children }: StationProviderProps) => {
  const result = useStation(id);
  return children(result);
};

export const useAllStations = (): Station[] => {
  const dispatch = useDispatch();
  const [{ result = [], successAt }, listStations] = useSlice(allStationsSlice);
  const shouldFetch = !successAt || moment(successAt) > moment().add(API_CACHE_TIME, 'ms');

  useEffect(() => {
    if (shouldFetch) {
      dispatch(listStations.request({ _limit: 100 }));
    }
  }, []); // eslint-disable-line

  return result;
};

export const useStationByNS = (ns: string | undefined): Station[] => {
  const dispatch = useDispatch();
  const [{ result = [], successAt }, listStations] = useSlice(stationByNSSlice);
  const shouldFetch = !successAt || moment(successAt) > moment().add(API_CACHE_TIME, 'ms');

  useEffect(() => {
    if (shouldFetch && ns) {
      dispatch(
        listStations.request({
          ns_like: ns,
          _limit: 100,
          _count: [ListStationsCountableField.VEHICLE, ListStationsCountableField.BATTERY],
        })
      );
    }
  }, []); // eslint-disable-line

  return result;
};

export function StationSelect(props: SelectProps) {
  const [{ selectedZone }] = useSlice(globalSlice);
  let stations = useAllStations() || [];

  if (selectedZone) {
    stations = stations.filter((c) => c.ns.includes(selectedZone));
  }

  return (
    <Select {...props}>
      {stations.map((s) => (
        <Select.Option key={s.id} value={s.id}>
          {s.name}
        </Select.Option>
      ))}
    </Select>
  );
}

export interface StationStateProps {
  state: StationStatus;
}

export function StationStateBadge({ state }: StationStateProps) {
  const found = StationStateUiMap[state];
  if (!found) return null;
  return <Badge color={found.color} text={found.label} />;
}

interface ChargerMeasurePayload {
  pattern: string;
  data: ChargerMeasure;
}

interface StationStatePayload {
  pattern: string;
  data: StationState;
}

export function StationStateWatcher() {
  const dispatch = useDispatch();
  const [, { pushChargerState }] = useSlice(chargerMeasureSlice);
  const [, { setStationState }] = useSlice(stationStateSlice);

  const handleMeasure = useCallback((topic: string, payload: ChargerMeasurePayload) => {
    if (topic.match(/stations\/.+\/chargers\/.+\/measure/)) {
      dispatch(pushChargerState(payload.data));
    }
  }, []);

  const handleHeartbeat = useCallback((topic: string, payload: StationStatePayload) => {
    if (topic.match(/stations\/.+\/heartbeat/)) {
      dispatch(setStationState(payload.data));
    }
  }, []);

  useMqtt({
    topic: `${MQTT_CONSUMER_GROUP}/stations/+/chargers/+/measure`,
    handler: handleMeasure,
  });

  useMqtt({
    topic: `${MQTT_CONSUMER_GROUP}/stations/+/heartbeat`,
    handler: handleHeartbeat,
  });

  // 打开注释，可以模拟数据
  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     dispatch(
  //       pushChargerState({
  //         chargerNo: '1000380001000006',
  //         stationNo: station.no || '111',
  //         chgOutputVolt: 661.4 + Math.random() * 100,
  //         chgOutputCurr: 400.8 + Math.random() * 100,
  //         batteryNo: '1000380001000006',
  //         recordAt: new Date().getTime(),
  //       })
  //     );
  //   }, 1000);
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);

  return null;
}
