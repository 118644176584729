import { Company, DeleteBillRequest, MekongAPIClient, UpdateCompanyRequest } from '@36node-mekong/sdk-ts';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { schema } from 'normalizr';

import * as config from 'src/config';

import { injectToken, paramsSerializer } from './helpers';

/**
 * schemas
 */

export const appointmentSchema = new schema.Entity('Reservations');
export const batteryHealthSchema = new schema.Entity('batteryHealth');
export const batteryHealthStatSchema = new schema.Entity('batteryHealthStats');
export const batterySchema = new schema.Entity('batteries');
export const billSchema = new schema.Entity('bills');
export const chargeRecordSchema = new schema.Entity('chargeRecords');
export const chargerSchema = new schema.Entity('chargers');
export const companySchema = new schema.Entity('companies');
export const companyWithVehicleSchema = new schema.Entity('companiesWithVehicle');
export const deviceFaultSchema = new schema.Entity('deviceFaults');
export const driverSchema = new schema.Entity('drivers');
export const faultRecordSchema = new schema.Entity('faultRecord');
export const maintainerSchema = new schema.Entity('maintainers');
export const orderSchema = new schema.Entity('orders');
export const paperSchema = new schema.Entity('papers');
export const rateTemplateSchema = new schema.Entity('rateTemplates');
export const robotSchema = new schema.Entity('robots');
export const sheetSchema = new schema.Entity('sheets');
export const staffShiftSchema = new schema.Entity('staffShifts');
export const stationSchema = new schema.Entity('stations');
export const stationStateSchema = new schema.Entity('stationStates', {}, { idAttribute: 'no' });
export const swapRecordSchema = new schema.Entity('swapRecords');
export const vehicleSchema = new schema.Entity('vehicles', {}, { idAttribute: 'vin' });
export const workOrderSchema = new schema.Entity('workOrders', {
  sheets: [sheetSchema],
  papers: [paperSchema],
});
export const operationSchema = new schema.Entity('operations');

export const vehicleStateSchema = new schema.Entity('vehicleStates');

export const wastageSchema = new schema.Entity('wastages');

export class Mekong extends MekongAPIClient {
  /**
   * 如果有自定义的方法，此处实现
   */

  // 批量修改company
  batchUpdateCompanies = async (reqs: UpdateCompanyRequest[], config?: AxiosRequestConfig) => {
    const resps = await Promise.all(reqs.map((req) => this.updateCompany(req, config)));

    let response: AxiosResponse<Company[]> = resps[0] as any;
    response.data = resps.map((r) => r.data);

    return response;
  };

  batchDeleteBills = async (reqs: DeleteBillRequest[], config?: AxiosRequestConfig) => {
    const resps = await Promise.all(reqs.map((req) => this.deleteBill(req, config)));
    let response: AxiosResponse<void> = resps[0] as any;
    return response;
  };
}

const mekongAxiosInstance = axios.create({ baseURL: config.MEKONG_ENDPOINT, paramsSerializer });
mekongAxiosInstance.interceptors.request.use(injectToken);

export const mekong = new Mekong(mekongAxiosInstance);
