import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { isEmpty, min, pickBy } from 'lodash';
import * as qs from 'qs';

import { getToken } from './stargate';

const MAX_DOWNLOAD_RECORDS = 50000;

export type Response<T> = Promise<AxiosResponse<T>>;

export type Entity = {
  id: string;
  ns: string; // 命名空间
  source?: string; // 来源
  creatAt?: string; // 创建时间
  updateAt?: string; // 修改时间
};

export type ListParams = {
  _limit?: number;
  _offset?: number;
  _sort?: string;
  ns?: string;
  source?: string;
  ns_like?: string;
};

export function injectToken(config: AxiosRequestConfig) {
  const token = getToken();
  if (token) {
    const headers = config.headers || {};
    headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}

function filter(value: any) {
  if (typeof value === 'string' && value.trim() === '') return false;
  if (typeof value === 'object' && isEmpty(value)) return false;
  return true;
}

export function paramsSerializer(params: any): string {
  return qs.stringify(pickBy(params, filter), { indices: false, skipNulls: true });
}

const limitQ = { _limit: 1000 };
export function listAllPages<K, T>(list: (params: K) => Response<T[]>, args: K, items: T[] = []): Promise<T[]> {
  return list({ ...args, ...limitQ }).then((response) => {
    const { data, headers } = response;
    const { 'x-total-count': totalCount } = headers;
    const newItems = items.concat(data);
    const max = min([MAX_DOWNLOAD_RECORDS, parseInt(totalCount)]);
    if (newItems.length < max) {
      return listAllPages(list, { ...args, _offset: newItems.length, ...limitQ }, newItems);
    }
    return Promise.resolve(newItems);
  });
}
