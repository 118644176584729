import React from 'react';
import { IndexRouteObject, RouteObject, useRoutes } from 'react-router-dom';

import { RequireAuth } from 'src/features';
import { RoutePathDefinition } from 'src/lib/routing';

import { staticRoutes } from './sitemap';

function wrapWithAuth(route: RoutePathDefinition): RouteObject {
  let { path, element, children, ...rest } = route;
  if (element && route.privileges) {
    element = (
      <RequireAuth privileges={route.privileges}>
        <>{element}</>
      </RequireAuth>
    );
  }
  if (children) {
    children = children.map((r) => wrapWithAuth(r));
  } else {
    path = path ? `${path}/*` : path;
  }
  return { ...rest, path, element, children } as IndexRouteObject;
}

const routes = staticRoutes.map(wrapWithAuth);

export const RoutesRenderer = () => {
  const renderedRoutes = useRoutes(routes as any);
  return renderedRoutes;
};
