import { NamespaceLabel } from '@36node-mekong/sdk-ts';
import { Select, SelectProps, Tag, TreeSelect, TreeSelectProps } from 'antd';
import { get } from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { API_CACHE_TIME } from 'src/config';
import { useSlice } from 'src/lib/redux-toolkit';
import { Namespace } from 'src/services';

import { allNamespacesSlice, globalSlice, namespaceByIdSlice } from './slice';

export interface NamespaceProviderProps {
  id: string;
  children: (namespace: Namespace | undefined) => JSX.Element;
}

export const namespaceLabelUiMap = {
  [NamespaceLabel.TRANSPORT_COMPANY]: { label: '运输公司', color: '#2db7f5' },
  [NamespaceLabel.MAINTENANCE_COMPANY]: { label: '维保公司', color: '#2db7f5' },
  [NamespaceLabel.ZONE]: { label: '区域', color: '#87d068' },
};

export const useAllNamespaces = (): Namespace[] => {
  const dispatch = useDispatch();
  const [{ result = [], successAt }, listNamespace] = useSlice(allNamespacesSlice);
  const shouldFetch = !successAt || moment(successAt) > moment().add(API_CACHE_TIME, 'ms');

  useEffect(() => {
    if (shouldFetch) {
      dispatch(listNamespace.request({ _limit: 100 }));
    }
  }, []); // eslint-disable-line

  return result;
};

export const useNamespace = (namespaceId: string): Namespace | undefined => {
  const dispatch = useDispatch();
  const [{ result, successAt }, getNamespaceById] = useSlice(namespaceByIdSlice, { key: namespaceId });
  const shouldFetch = !successAt || moment(successAt) > moment().add(API_CACHE_TIME, 'ms');

  useEffect(() => {
    if (shouldFetch && namespaceId) {
      dispatch(getNamespaceById.request({ namespace: namespaceId }));
    }
  }, [namespaceId]);

  return result;
};

export const NamespaceProvider = ({ id, children }: NamespaceProviderProps) => {
  const result = useNamespace(id);
  return children(result);
};

type NamespaceSelectProps = {
  label?: string;
} & SelectProps<Namespace>;

export function NamespaceSelect(props: NamespaceSelectProps) {
  let namespaces = useAllNamespaces();
  if (props.label) namespaces = namespaces.filter((ns) => ns.labels?.includes(props.label as string));

  return (
    <Select {...props}>
      {namespaces.map((ns) => (
        <Select.Option key={ns.id} value={ns.id}>
          {ns.name}
        </Select.Option>
      ))}
    </Select>
  );
}

export function NamespaceTreeSelect(props: TreeSelectProps<Namespace>) {
  let namespaces = useAllNamespaces();
  const treeData = listToTree(namespaces);

  return (
    <TreeSelect
      allowClear
      treeDefaultExpandAll
      style={{ width: '100%' }}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      placeholder="Please select"
      treeData={treeData}
      {...props}
    />
  );
}

export function ZoneTreeSelect(props: TreeSelectProps<string>) {
  const dispatch = useDispatch();
  const [, globalActions] = useSlice(globalSlice);
  const namespaces = useAllNamespaces();
  const treeData = listToTree(namespaces.filter((n) => n.labels?.includes(NamespaceLabel.ZONE)));

  const handleChange: TreeSelectProps['onChange'] = (value, label, extra) => {
    dispatch(globalActions.setSelectedZone(value));
    props.onChange?.(value, label, extra);
  };

  useEffect(() => {
    return () => {
      dispatch(globalActions.setSelectedZone(undefined));
    };
  }, []);

  return (
    <TreeSelect
      treeDefaultExpandAll
      style={{ width: '100%' }}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      placeholder="Please select"
      treeData={treeData}
      {...props}
      onChange={handleChange}
    />
  );
}

export function NamespaceLabelSelect(props: SelectProps) {
  return (
    <Select {...props}>
      {Object.entries(namespaceLabelUiMap).map(([key, obj]) => (
        <Select.Option key={key} value={key}>
          {obj.label}
        </Select.Option>
      ))}
    </Select>
  );
}

export function NamespaceLabelTag({ label, className }: { label: NamespaceLabel; className?: string }) {
  const found = namespaceLabelUiMap[label];
  if (!found) return null;
  return (
    <Tag className={className} color={found.color}>
      {found.label}
    </Tag>
  );
}

export function getId(recordOrIdString: any) {
  if (typeof recordOrIdString === 'string') return recordOrIdString;
  return get(recordOrIdString, 'id');
}

/**
 * 将 namespaces 数组转换成 treeData
 * @param arr namespaces
 * @returns treeData
 */
export function listToTree(arr: any) {
  const res = arr.map((item: any) => ({
    ...item,
    children: [],
    key: getId(item),
    value: getId(item),
    title: item.name || item.title,
    label: item.name || item.title,
  }));
  const m = res.reduce((acc: any, cur: any) => {
    // eslint-disable-next-line
    acc[cur.id] = cur;
    return acc;
  }, {});

  res.forEach((item: any) => {
    const parentId = getId(item.parent);

    if (!parentId) return;
    const p = m[parentId];
    if (!p) return;
    p.children.push(item);
    // eslint-disable-next-line
    item.isChild = true;
  });

  return res.filter((item: any) => !item.isChild);
}
