import { Company, ListCompanyCountableField } from '@36node-mekong/sdk-ts';
import { Select, SelectProps } from 'antd';
import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { API_CACHE_TIME } from 'src/config';
import { useSlice } from 'src/lib/redux-toolkit';

import {
  allCompaniesSlice,
  allMaintenanceCompaniesSlice,
  allTransportCompaniesSlice,
  companyByIdSlice,
  globalSlice,
} from './slice';

export interface CompanyProviderProps {
  id: string | undefined;
  children: (company: Company | undefined) => JSX.Element;
}

export const useAllCompanies = (): Company[] => {
  const dispatch = useDispatch();
  const [{ result = [], successAt }, listCompany] = useSlice(allCompaniesSlice);
  const shouldFetch = !successAt || moment(successAt) > moment().add(API_CACHE_TIME, 'ms');

  useEffect(() => {
    if (shouldFetch) {
      dispatch(listCompany.request({ _limit: 100 }));
    }
  }, []);

  return result;
};

export const useAllTransportCompanies = (): Company[] => {
  const dispatch = useDispatch();
  const [{ result = [], successAt }, listCompany] = useSlice(allTransportCompaniesSlice);
  const shouldFetch = !successAt || moment(successAt) > moment().add(API_CACHE_TIME, 'ms');

  useEffect(() => {
    if (shouldFetch) {
      dispatch(listCompany.request({ _count: [ListCompanyCountableField.VEHICLE], _limit: 100 }));
    }
  }, []);

  return result;
};

export const useAllMaintenanceCompanies = (): Company[] => {
  const dispatch = useDispatch();
  const [{ result = [], successAt }, listCompany] = useSlice(allMaintenanceCompaniesSlice);
  const shouldFetch = !successAt || moment(successAt) > moment().add(API_CACHE_TIME, 'ms');

  useEffect(() => {
    if (shouldFetch) {
      dispatch(listCompany.request({ _limit: 100 }));
    }
  }, []);

  return result;
};

export const useCompany = (id: string | undefined): Company | undefined => {
  const dispatch = useDispatch();
  const [{ result, successAt }, getCompanyById] = useSlice(companyByIdSlice, { key: id });
  const shouldFetch = !successAt || moment(successAt) > moment().add(API_CACHE_TIME, 'ms');

  useEffect(() => {
    if (shouldFetch && id) {
      dispatch(getCompanyById.request({ company: id }));
    }
  }, [id]);

  return result;
};

export const CompanyProvider = ({ id, children }: CompanyProviderProps) => {
  const result = useCompany(id);
  return children(result);
};

export function TransportCompanySelect(props: SelectProps<string>) {
  const [{ selectedZone }] = useSlice(globalSlice);
  let companies = useAllTransportCompanies();

  if (selectedZone) {
    companies = companies.filter((c) => c.ns.includes(selectedZone));
  }

  return (
    <Select
      showSearch
      {...props}
      filterOption={(input, option) =>
        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
      }
    >
      {companies.map((c) => (
        <Select.Option key={c.id} value={c.id}>
          {c.name}
        </Select.Option>
      ))}
    </Select>
  );
}

export function MaintenanceCompanySelect(props: SelectProps<string>) {
  const companies = useAllMaintenanceCompanies();

  return (
    <Select
      showSearch
      {...props}
      filterOption={(input, option) =>
        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
      }
    >
      {companies.map((c) => (
        <Select.Option key={c.id} value={c.id}>
          {c.name}
        </Select.Option>
      ))}
    </Select>
  );
}
