import { AdColumnsType } from 'src/components/antd/ad-table';

export type ExportStartPayload<T, P> = {
  args: T;
  columns: AdColumnsType<P>;
};

export enum ExportState {
  INITIAL = 0,
  PREPARE, // 准备数据
  PROCESSING,
  SUCCESS,
  FAILURE,
}

const ExportStateUiMap = {
  [ExportState.INITIAL]: { label: '' },
  [ExportState.PREPARE]: { label: '准备数据...' },
  [ExportState.PROCESSING]: { label: '导出数据...' },
  [ExportState.SUCCESS]: { label: '导出成功' },
  [ExportState.FAILURE]: { label: '导出失败' },
};

export const getExportStateLabel = (state: ExportState) => ExportStateUiMap[state]?.label;
