export type ImportError = {
  row: number;
  message: string;
};

export enum ImportState {
  INITIAL = 0,
  READY,
  INVALID,
  PROCESSING,
  SUCCESS,
  FAILURE,
}
