import { OrderCountableField, OrderGroupField, OrderType } from '@36node-mekong/sdk-ts';
import { Select, SelectProps } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { aggregateCompanyOrdersSlice, aggregateCompanySwapRecordsSlice } from 'src/containers/por/slice';
import { useSlice } from 'src/lib/redux-toolkit';

import { useAllTransportCompanies } from './company';

export interface CompanyChargeProps {
  station?: string;
  startAt?: string;
  endAt?: string;
  num?: number;
  ns?: string;
}

export const orderTypeUiMap = {
  [OrderType.SWAP]: { label: '换电' },
  [OrderType.CHARGE]: { label: '充电' },
};

export function getOrderTypeLabel(type: OrderType) {
  return orderTypeUiMap[type]?.label;
}

export function OrderTypeSelect(props: SelectProps) {
  return (
    <Select {...props}>
      {Object.entries(orderTypeUiMap).map(([type, obj]) => (
        <Select.Option key={type} value={type}>
          {obj.label}
        </Select.Option>
      ))}
    </Select>
  );
}

export interface aggregateByCompany {
  name: string;
  value: number;
  elec: number;
  vehicleCount: number;
  swapCount: number;
}

export function useAggregateByCompany({ station, startAt, endAt, num, ns }: CompanyChargeProps) {
  const dispatch = useDispatch();
  const [{ result: ordersAggByCompany = [] }, aggOrdersByCompany] = useSlice(aggregateCompanyOrdersSlice);
  const [{ result: swapRecordsAggByCompany = [] }, aggSwapRecordsByCompany] = useSlice(
    aggregateCompanySwapRecordsSlice
  );
  const companies = useAllTransportCompanies();
  const [result, setData] = useState<aggregateByCompany[] | undefined>(undefined);
  const [total, setTotalElec] = useState(0);

  useEffect(() => {
    dispatch(
      aggOrdersByCompany.request({
        _group: [OrderGroupField.NS],
        _count: [OrderCountableField.ELEC, OrderCountableField.VEHICLE],
        _sort: '-elec',
        abnormal: false,
        at_gte: startAt,
        at_lte: endAt,
        ns_like: ns,
        station: station,
      })
    );
    dispatch(
      aggSwapRecordsByCompany.request({
        _group: [OrderGroupField.NS],
        endAt_gte: startAt,
        endAt_lte: endAt,
        ns_like: ns,
        station: station,
      })
    );
  }, [station, startAt, endAt, ns]);

  useEffect(() => {
    if (
      ordersAggByCompany &&
      ordersAggByCompany.length > 0 &&
      swapRecordsAggByCompany &&
      swapRecordsAggByCompany.length > 0 &&
      companies &&
      companies.length > 0
    ) {
      let data = [],
        i = 0;
      const totalElec = ordersAggByCompany.reduce((elec, item) => {
        return elec + item.elec;
      }, 0);

      setTotalElec(totalElec);

      for (; i < num! - 1 && i < ordersAggByCompany.length; ++i) {
        const orderAgg = ordersAggByCompany[i];
        const company = companies.find((item) => item.id === orderAgg.ns);
        if (company) {
          const swapAgg = swapRecordsAggByCompany.find((item) => item.ns === orderAgg.ns);
          data.push({
            name: company.name,
            value: parseFloat((orderAgg.elec / totalElec).toFixed(2)),
            elec: orderAgg.elec,
            vehicleCount: company.vehicleCount || 0,
            swapCount: swapAgg?.count || 0,
          });
        }
      }
      let j = i;
      if (j === num! - 1 && ordersAggByCompany.length > num! - 1) {
        let otherSumQuantity = 0,
          otherVehicleCount = 0,
          otherSwapCount = 0;
        while (j < ordersAggByCompany.length) {
          const orderAgg = ordersAggByCompany[j];
          const company = companies.find((item) => item.id === orderAgg.ns);
          const swapAgg = swapRecordsAggByCompany.find((item) => item.ns === orderAgg.ns);
          // eslint-disable-next-line no-loop-func
          otherSumQuantity += ordersAggByCompany[j].elec;
          otherVehicleCount += company?.vehicleCount || 0;
          otherSwapCount += swapAgg?.count || 0;
          j += 1;
        }
        data.push({
          name: '其他',
          value: parseFloat((otherSumQuantity / totalElec).toFixed(2)),
          elec: otherSumQuantity,
          vehicleCount: otherVehicleCount,
          swapCount: otherSwapCount,
        });
      }
      setData(data);
    }
  }, [ordersAggByCompany, swapRecordsAggByCompany, companies]);

  return { result, total };
}
