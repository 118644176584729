import { FaultCategory, WorkOrder, WorkOrderStatus } from '@36node-mekong/sdk-ts';
import { Badge, Select, SelectProps, Spin } from 'antd';
import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { API_CACHE_TIME } from 'src/config';
import { useSlice } from 'src/lib/redux-toolkit';

import { workOrderByIdSlice } from './slice';

export const workOrderStateUiMap = {
  [WorkOrderStatus.OPEN]: { label: '未完成', color: '#ffbf00' },
  [WorkOrderStatus.CLOSED]: { label: '已完成', color: '#1890ff' },
};

export const workOrderMaintenanceStateUiMap = {
  [WorkOrderStatus.OPEN]: { label: '未完成', color: '#ffbf00' },
  [WorkOrderStatus.CLOSED]: { label: '已完成', color: '#46a45a' },
};

export const faultCategoryUiMap = {
  [FaultCategory.STATION]: '设备报警',
  [FaultCategory.BATTERY]: '电池报警',
  [FaultCategory.VEHICLE]: '电池报警',
  [FaultCategory.WARNING]: '异常报警',
};

export function WorkOrderStateBadge({ status }: { status: WorkOrderStatus }) {
  const found = workOrderStateUiMap[status];
  if (!found) return null;
  return <Badge color={found.color} text={found.label} />;
}

export function WorkOrderMaintenanceStateBadge({ status }: { status: WorkOrderStatus }) {
  const found = workOrderMaintenanceStateUiMap[status];
  if (!found) return null;
  return <Badge color={found.color} text={found.label} />;
}

export function WorkOrderMaintenanceStateSelect(props: SelectProps) {
  return (
    <Select {...props}>
      {Object.entries(workOrderMaintenanceStateUiMap).map(([key, obj]) => (
        <Select.Option key={key} value={key}>
          {obj.label}
        </Select.Option>
      ))}
    </Select>
  );
}

export function WorkOrderStateSelect(props: SelectProps) {
  return (
    <Select {...props}>
      {Object.entries(workOrderStateUiMap).map(([key, obj]) => (
        <Select.Option key={key} value={key}>
          {obj.label}
        </Select.Option>
      ))}
    </Select>
  );
}

export const useWorkOrder = (id?: string): WorkOrder | undefined => {
  const dispatch = useDispatch();
  const [{ result, successAt }, getById] = useSlice(workOrderByIdSlice, { key: id });
  const shouldFetch = !successAt || moment(successAt) > moment().add(API_CACHE_TIME, 'ms');

  useEffect(() => {
    if (shouldFetch && id) {
      dispatch(getById.request({ workOrder: id }));
    }
  }, [id]);

  return result;
};

export const WorkOrderLink = ({ id }: { id: string }) => <Link to={`/maintenance/work-order/detail/${id}`}>{id}</Link>;
export const WorkOrderLinkWithTitle = ({ id }: { id: string }) => {
  const workOrder = useWorkOrder(id);
  return (
    <Spin spinning={!workOrder}>
      <Link to={`/maintenance/work-order/detail/${id}`}>维保单: {workOrder?.title}</Link>
    </Spin>
  );
};
