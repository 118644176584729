import axios, { AxiosInstance } from 'axios';
import { get } from 'lodash';
import { schema } from 'normalizr';

import * as config from 'src/config';
import { ROOT_NS, STARGATE_SCOPE } from 'src/config';

import { Entity, injectToken, ListParams, paramsSerializer, Response } from './helpers';

/**
 * session
 */

export type Session = {
  id: string;
  expireAt: string;
  active: boolean;
  ns: string;
  roles: string[];
  privileges: string[];
  token: string;
  tokenExpireAt: string;
  user: User;
  provider: string;
};

export type CreateSessionRequest = {
  body: {
    provider: string;
    username: string;
    password: string;
    ns: string;
  };
};

export type DeleteSessionRequest = {
  session: string;
};

export type GetSessionRequest = {
  session: string;
};

/**
 * namesapce
 */

export type Namespace = {
  id: string;
  parent: string;
  name: string;
  desc?: string;
  labels?: string[];
  data?: string;
};

export type GetNamespaceRequest = {
  namespace: string;
};

export type ListNamespaceRequest = {
  id_like?: string;
  parent_like?: string;
  name_like?: string;
  labels?: string;
} & ListParams;

export type CreateNamespaceRequest = {
  body: {
    id: string;
    data?: string;
    desc: string;
    labels?: string[];
    name: string;
    parent?: string;
  };
};

export type UpdateNamespaceRequest = {
  namespace: string;
  body: {
    data?: string;
    desc?: string;
    labels?: string[];
    name?: string;
    parent?: string;
  };
};

export type DeleteNamespaceRequest = {
  namespace: string;
};

/**
 * user
 */

export type User = {
  active: boolean;
  nickname?: string;
  phone?: string;
  roles?: string[];
  username: string;
  type: string;
  labels: string[];
} & Entity;

export type GetUserRequest = {
  user: string;
};

export type ListUserRequest = {
  name_like?: string;
  phone?: string;
  roles?: string;
  type?: string | string[];
  type_ne?: string;
} & ListParams;

export type CreateUserRequest = {
  body: {
    active?: boolean;
    nickname?: string;
    ns: string; // 命名空间
    password: string;
    phone?: string;
    roles?: string[];
    source?: string; // 来源
    type?: string; // 用户类型
    username: string;
    createBy?: string;
  };
};

export type UpdateUserRequest = {
  user: string;
  body: {
    active?: boolean;
    nickname?: string;
    ns?: string; // 命名空间
    password?: string;
    phone?: string;
    roles?: string[];
    source?: string; // 来源
  };
};

export type DeleteUserRequest = {
  user: string;
};

//scope roles

export type ScopeRole = {
  name?: string;
  type?: string;
  desc?: string;
  privileges?: string[];
} & Entity;

export type CreateScopeRoleRequest = {
  scopeId: string;
  body: {
    name?: string;
    desc?: string;
    privileges?: string[];
  } & {
    name: string;
    type: string;
  };
};

export type ListScopeRolesRequest = {
  _limit?: number;
  _offset?: number;
  scopeId?: string;
};

export type GetScopeRoleRequest = {
  scopeId: string;
  roleId: string;
};

export type UpdateScopeRoleRequest = {
  scopeId: string;
  roleId: string;
  /**
   * 角色配置
   */
  body: {
    /**
     * 角色名称
     */
    name?: string;
    /**
     * 角色描述
     */
    desc?: string;
    privileges?: string[];
  };
};

export type DeleteScopeRoleRequest = {
  scopeId: string;
  roleId: string;
};

/**
 * schemas
 *
 */
export const namespaceSchema = new schema.Entity('namespaces');
export const userSchema = new schema.Entity('users');
export const scopeRoleSchema = new schema.Entity('scopeRoles');

const SESSION_ID = 'session_id';
const SESSION = 'session';

export const getSessionId = () => sessionStorage.getItem(SESSION_ID) || localStorage.getItem(SESSION_ID);
export const saveSessionId = (sessionId: string) => localStorage.setItem(SESSION_ID, sessionId);
export const removeSessionId = () => localStorage.removeItem(SESSION_ID);

export const saveSession = (session: Session) => sessionStorage.setItem(SESSION, JSON.stringify(session));
export const getSession = (): Session | null => JSON.parse(sessionStorage.getItem(SESSION) || 'null');
export const removeSession = () => sessionStorage.removeItem(SESSION);
export const getToken = () => get(getSession(), 'token');

export class Stargate {
  public client: AxiosInstance;
  constructor(client: AxiosInstance) {
    if (!client.defaults.baseURL) {
      throw new Error("client's baseURL MUST specified");
    }
    this.client = client;
  }

  login = (req: CreateSessionRequest): Response<Session> => {
    return this.client
      .request<Session>({
        url: `/sessions`,
        method: 'post',
        data: req.body,
      })
      .then((res) => {
        const session = res.data;
        if (!ROOT_NS.includes(session.ns) && !session.ns.includes(ROOT_NS)) {
          throw new Error(`Root ns:${ROOT_NS} not match with session ns:${session.ns}`);
        }
        saveSession(session);
        saveSessionId(session.id);
        return res;
      });
  };

  refresh = (req: GetSessionRequest): Response<Session> => {
    return this.client
      .request<Session>({
        url: `/sessions/${req.session}`,
        method: 'get',
      })
      .then((res) => {
        const session = res.data;
        if (!ROOT_NS.includes(session.ns) && !session.ns.includes(ROOT_NS)) {
          throw new Error(`Root ns:${ROOT_NS} not match with session ns:${session.ns}`);
        }
        saveSession(session);
        return res;
      });
  };

  logout = (req: DeleteSessionRequest): Response<void> => {
    return this.client
      .request<void>({
        url: `/sessions/${req.session}`,
        method: 'delete',
      })
      .finally(() => {
        removeSession();
        removeSessionId();
        window.location.href = '/login';
      });
  };

  listNamespaces = (req: ListNamespaceRequest): Response<Namespace[]> => {
    return this.client.request<Namespace[]>({
      url: `/namespaces`,
      method: 'get',
      params: req,
    });
  };

  getNamespace = (req: GetNamespaceRequest): Response<Namespace> => {
    return this.client.request<Namespace>({
      url: `/namespaces/${req.namespace}`,
      method: 'get',
    });
  };

  createNamespace = (req: CreateNamespaceRequest): Response<Namespace> => {
    return this.client.request<Namespace>({
      url: `/namespaces`,
      method: 'post',
      data: req.body,
    });
  };

  updateNamespace = (req: UpdateNamespaceRequest): Response<Namespace> => {
    return this.client.request<Namespace>({
      url: `/namespaces/${req.namespace}`,
      method: 'put',
      data: req.body,
    });
  };

  deleteNamespace = (req: DeleteNamespaceRequest): Response<void> => {
    return this.client.request<void>({
      url: `/namespaces/${req.namespace}`,
      method: 'delete',
    });
  };

  listUsers = (req: ListUserRequest): Response<User[]> => {
    return this.client.request<User[]>({
      url: `/users`,
      method: 'get',
      params: req,
    });
  };

  getUser = (req: GetUserRequest): Response<User> => {
    return this.client.request<User>({
      url: `/users/${req.user}`,
      method: 'get',
    });
  };

  createUser = (req: CreateUserRequest): Response<User> => {
    return this.client.request<User>({
      url: `/users`,
      method: 'post',
      data: req.body,
    });
  };

  updateUser = (req: UpdateUserRequest): Response<User> => {
    return this.client.request<User>({
      url: `/users/${req.user}`,
      method: 'put',
      data: req.body,
    });
  };

  deleteUser = (req: DeleteUserRequest): Response<void> => {
    return this.client.request({
      url: `/users/${req.user}`,
      method: 'delete',
    });
  };

  checkPhoneExist = async (phone: string): Promise<boolean> => {
    const found = await this.listUsers({ phone, ns_like: STARGATE_SCOPE });
    return found.data && found.data.length > 0;
  };

  createScopeRole = (req: CreateScopeRoleRequest): Response<ScopeRole> => {
    return this.client.request<ScopeRole>({
      url: `/scopes/${req.scopeId}/roles`,
      method: 'post',
      data: req.body,
    });
  };

  listScopeRoles = (req: ListScopeRolesRequest): Response<ScopeRole[]> => {
    return this.client.request<ScopeRole[]>({
      url: `/scopes/${req.scopeId}/roles`,
      method: 'get',
      params: req,
    });
  };

  getScopeRole = (req: GetScopeRoleRequest): Response<ScopeRole> => {
    return this.client.request<ScopeRole>({
      url: `/scopes/${req.scopeId}/roles/${req.roleId}`,
      method: 'get',
    });
  };

  updateScopeRole = (req: UpdateScopeRoleRequest): Response<ScopeRole> => {
    return this.client.request<ScopeRole>({
      url: `/scopes/${req.scopeId}/roles/${req.roleId}`,
      method: 'put',
      data: req.body,
    });
  };

  deleteScopeRole = (req: DeleteScopeRoleRequest): Response<void> => {
    return this.client.request({
      url: `/scopes/${req.scopeId}/roles/${req.roleId}`,
      method: 'delete',
    });
  };
}

const stargateAxiosInstance = axios.create({ baseURL: config.STARGATE_ENDPOINT, paramsSerializer });
stargateAxiosInstance.interceptors.request.use(injectToken);

export const stargate = new Stargate(stargateAxiosInstance);
