import { FeeType, RateTemplate, RateTemplateType } from '@36node-mekong/sdk-ts';
import { Radio, RadioProps, Select, SelectProps } from 'antd';
import { isNil } from 'lodash';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useSlice } from 'src/lib/redux-toolkit';

import { allRateTemplatesSlice, rateTemplateByIdSlice } from './slice';

export interface RateTemplateProviderProps {
  id: string;
  children: (rateTemplate: RateTemplate | undefined) => JSX.Element;
}

const RateTemplateTypeUiMap = {
  [RateTemplateType.ELECTRIC]: { label: '电费模板' },
  [RateTemplateType.SERVICE]: { label: '服务费模板' },
  [RateTemplateType.PACKAGE]: { label: '服务包模板' },
};

const FeeTypeUiMap = {
  [FeeType.TOP_PEAK]: { label: '尖时段' },
  [FeeType.PEAK]: { label: '峰时段' },
  [FeeType.FLAT]: { label: '平时段' },
  [FeeType.VALLEY]: { label: '谷时段' },
  [FeeType.SERVICE]: { label: '服务费' },
  [FeeType.PACKAGE]: { label: '服务包' },
  [FeeType.SERVICE_DEDUCT]: { label: '服务包抵扣' },
  [FeeType.SERVICE_EXCESS]: { label: '应计费服务费' },
};

export const getRateTemplateTypeLabel = (type: RateTemplateType) => RateTemplateTypeUiMap[type]?.label;
export const getFeeTypeLabel = (type: FeeType) => FeeTypeUiMap[type]?.label;

export const useAllRateTemplates = (): RateTemplate[] => {
  const dispatch = useDispatch();
  const [{ result = [], successAt }, listRateTemplate] = useSlice(allRateTemplatesSlice);

  useEffect(() => {
    if (!successAt) {
      dispatch(listRateTemplate.request({ _limit: 100 }));
    }
  }, []);

  return result;
};

export const useRateTemplate = (id: string | undefined): RateTemplate | undefined => {
  const dispatch = useDispatch();
  const [{ result }, getRateTemplateById] = useSlice(rateTemplateByIdSlice, { key: id });

  useEffect(() => {
    if (!result && id) {
      dispatch(getRateTemplateById.request({ rateTemplate: id }));
    }
  }, [id]);

  return result;
};

export const RateTemplateProvider = ({ id, children }: RateTemplateProviderProps) => {
  const result = useRateTemplate(id);
  return children(result);
};

type RateTemplateSelectProps = {
  type?: RateTemplateType;
} & SelectProps<RateTemplate>;

export function RateTemplateSelect(props: RateTemplateSelectProps) {
  let rateTemplates = useAllRateTemplates();
  if (!isNil(props.type)) rateTemplates = rateTemplates.filter((i) => i.type === props.type);

  return (
    <Select {...props}>
      {rateTemplates.map((c) => (
        <Select.Option key={c.id} value={c.id}>
          {c.name}
        </Select.Option>
      ))}
    </Select>
  );
}

export function RateTemplateTypeSelect(props: SelectProps) {
  return (
    <Select {...props}>
      {Object.entries(RateTemplateTypeUiMap).map(([key, obj]) => (
        <Select.Option key={key} value={key}>
          {obj.label}
        </Select.Option>
      ))}
    </Select>
  );
}

export function RateTemplateTypeRadio(props: RadioProps) {
  return (
    <Radio.Group {...props}>
      {Object.entries(RateTemplateTypeUiMap).map(([key, obj]) => (
        <Radio key={key} value={key}>
          {obj.label}
        </Radio>
      ))}
    </Radio.Group>
  );
}
