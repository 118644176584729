import { Vehicle, VehicleBattBoxStatus, VehicleState } from '@36node-mekong/sdk-ts';
import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { API_CACHE_TIME } from 'src/config';
import { makeEntitiesSelector, useSlice } from 'src/lib/redux-toolkit';
import { vehicleSchema } from 'src/services';

import { allVehiclesSlice, vehicleByIdSlice, vehicleStateByBatteryNoSlice } from './slice';

const selectVehicle = makeEntitiesSelector(vehicleSchema);

export interface VehicleProviderProps {
  id: string;
  children: (vehicle: Vehicle | undefined) => JSX.Element;
}

export interface VehicleListProps {
  ids: string[];
  children: (vehicleList: Vehicle[]) => JSX.Element;
}

export const VehicleBattBoxStatusUiMap = {
  [VehicleBattBoxStatus.CHARGING]: { label: '充电' },
  [VehicleBattBoxStatus.DISCHARGING]: { label: '放电' },
  [VehicleBattBoxStatus.OTHER]: { label: '其他' },
  [VehicleBattBoxStatus.SLEEP]: { label: '休眠' },
};
export const getVehicleBattBoxStatusLabel = (val?: VehicleBattBoxStatus) =>
  val && VehicleBattBoxStatusUiMap[val]?.label;

export const useVehicleByVin = (vin: string | undefined): Vehicle | undefined => {
  const dispatch = useDispatch();
  const result = useSelector((state: any) => selectVehicle(state, vin || 'nothing'));
  const [, getVehicleById] = useSlice(vehicleByIdSlice, { key: vin });

  useEffect(() => {
    if (vin && !result) {
      dispatch(getVehicleById.request({ vehicle: vin }));
    }
  }, [vin]);

  return result;
};

export const useVehicleStateByBatteryNo = (batteryNo: string | undefined): VehicleState | undefined => {
  const dispatch = useDispatch();
  const [{ result }, getVehicleStateByBatteryNo] = useSlice(vehicleStateByBatteryNoSlice, { key: batteryNo });

  useEffect(() => {
    if (batteryNo) {
      dispatch(getVehicleStateByBatteryNo.request({ key: batteryNo }));
    }
  }, [batteryNo]);

  return result;
};

export const useAllVehicles = (): Vehicle[] => {
  const dispatch = useDispatch();
  const [{ result = [], successAt }, listVehicles] = useSlice(allVehiclesSlice);
  const shouldFetch = !successAt || moment(successAt) > moment().add(API_CACHE_TIME, 'ms');

  useEffect(() => {
    if (shouldFetch) {
      dispatch(listVehicles.request({ _limit: 10000 }));
    }
  }, []); // eslint-disable-line

  return result;
};

export const VehicleProvider = ({ id, children }: VehicleProviderProps) => {
  const result = useVehicleByVin(id);
  return children(result);
};
