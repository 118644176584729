import { SwapPosition, SwapType } from '@36node-mekong/sdk-ts';
import { Select, SelectProps } from 'antd';

export const SwapPositionUiMap = {
  [SwapPosition.CABINET]: { label: '换仓' },
  [SwapPosition.A]: { label: 'A 侧换电' },
  [SwapPosition.B]: { label: 'B 侧换电' },
};

export const SwapTypeUiMap = {
  [SwapType.REMOTE_AUTO]: { label: '远程自动' },
  [SwapType.REMOTE_MANUAL]: { label: '远程手动' },
  [SwapType.LOCAL_MANUAL]: { label: '本地手动' },
};

export const getSwapPositionLabel = (val: SwapPosition) => SwapPositionUiMap[val]?.label;
export function SwapPositionSelect(props: SelectProps) {
  return (
    <Select {...props}>
      {Object.entries(SwapPositionUiMap).map(([type, obj]) => (
        <Select.Option key={type} value={type}>
          {obj.label}
        </Select.Option>
      ))}
    </Select>
  );
}

export const getSwapTypeLabel = (val: SwapType) => SwapTypeUiMap[val]?.label;
export function SwapTypeSelect(props: SelectProps) {
  return (
    <Select {...props}>
      {Object.entries(SwapTypeUiMap).map(([type, obj]) => (
        <Select.Option key={type} value={type}>
          {obj.label}
        </Select.Option>
      ))}
    </Select>
  );
}
