import { Select, SelectProps } from 'antd';
import { isArray } from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { API_CACHE_TIME, ROOT_NS } from 'src/config';
import { useSlice } from 'src/lib/redux-toolkit';
import { ScopeRole, User } from 'src/services';

import { allRolesSlice, allUsersSlice, userByIdSlice } from './slice';

export interface UserProviderProps {
  id: string | undefined;
  children: (user: User | undefined) => JSX.Element;
}

export interface RolesNameProviderProps {
  types: string[];
}

export const useAllRoles = (): ScopeRole[] => {
  const dispatch = useDispatch();
  const [{ result = [], successAt }, listScopeRoles] = useSlice(allRolesSlice);
  const shouldFetch = !successAt || moment(successAt) > moment().add(API_CACHE_TIME, 'ms');

  useEffect(() => {
    if (shouldFetch) {
      dispatch(listScopeRoles.request({ _limit: 100, scopeId: ROOT_NS }));
    }
  }, []); // eslint-disable-line

  return result;
};

export const RolesNameProvider = ({ types }: RolesNameProviderProps) => {
  const allRoles = useAllRoles();
  return allRoles
    .filter((r) => types.includes(r.type!))
    .map((r) => r.name)
    .join(',') as any;
};

export function RoleSelect(props: SelectProps) {
  const roles = useAllRoles();

  let { value, ...rest } = props;
  value = isArray(value) ? value.filter((i: string) => roles.find((r) => r.type === i)) : value;
  return (
    <Select {...rest} value={value}>
      {roles.map((role) => (
        <Select.Option key={role.type} value={role.type}>
          {role.name}
        </Select.Option>
      ))}
    </Select>
  );
}

export const useAllUsers = (): User[] => {
  const dispatch = useDispatch();
  const [{ result = [], successAt }, listUsers] = useSlice(allUsersSlice);
  const shouldFetch = !successAt || moment(successAt) > moment().add(API_CACHE_TIME, 'ms');

  useEffect(() => {
    if (shouldFetch) {
      dispatch(listUsers.request({ _limit: 1000 }));
    }
  }, []); // eslint-disable-line

  return result;
};

export const useUser = (userId: string | undefined): User | undefined => {
  const dispatch = useDispatch();
  const [{ result, successAt }, getUserById] = useSlice(userByIdSlice, { key: userId });
  const shouldFetch = !successAt || moment(successAt) > moment().add(API_CACHE_TIME, 'ms');

  useEffect(() => {
    if (shouldFetch && userId) {
      dispatch(getUserById.request({ user: userId }));
    }
  }, [userId]);

  return result;
};

export const UserProvider = ({ id, children }: UserProviderProps) => {
  const result = useUser(id);
  return children(result);
};

interface UserSelectProps extends SelectProps {
  roles?: string[];
}

export function UserSelect(props: UserSelectProps) {
  const { roles } = props;
  let users = useAllUsers();
  if (roles) {
    users = users.filter((user) => roles.some((role) => user.roles?.includes(role)));
  }

  return (
    <Select
      {...props}
      showSearch
      optionFilterProp="label"
      filterOption
      options={users.map((user) => ({
        label: user.nickname,
        value: user.id,
      }))}
    />
  );
}
