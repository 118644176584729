import { useLocation } from 'react-router-dom';

import { ActiveRoutePath } from './ActiveRoutePath';
import { mapDefinitionToActivePath } from './mapDefinitionToActivePath';
// eslint-disable-next-line import/order
import { RoutePathDefinition } from './RoutePathDefinition';

export function useActiveRoutePaths(routes: RoutePathDefinition[]): ActiveRoutePath[] {
  const location = useLocation();
  const activeRoutePaths = mapDefinitionToActivePath(routes, location.pathname);
  return activeRoutePaths;
}
