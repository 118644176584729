import { Reservation, ReservationStatus } from '@36node-mekong/sdk-ts';
import { Badge, Select, SelectProps } from 'antd';

export const reservationStatusUiMap = {
  [ReservationStatus.PENDING]: { label: '未换电', color: 'blue' },
  [ReservationStatus.DONE]: { label: '已换电', color: 'green' },
  [ReservationStatus.CANCEL]: { label: '已取消', color: 'gray' },
};

export function getReservationStatusLabel(status: ReservationStatus) {
  return reservationStatusUiMap[status]?.label;
}

export function ReservationStatusBadge({ record }: { record: Reservation }) {
  if (record.overdue) {
    return <Badge color="yellow" text="已过期" />;
  }

  const found = reservationStatusUiMap[record.status];
  if (!found) return null;
  return <Badge color={found.color} text={found.label} />;
}

export function ReservationStatusSelect(props: SelectProps) {
  return (
    <Select {...props}>
      {Object.entries(reservationStatusUiMap).map(([state, obj]) => (
        <Select.Option key={state} value={state}>
          {obj.label}
        </Select.Option>
      ))}
    </Select>
  );
}
