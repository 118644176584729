import { DownOutlined, LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Breadcrumb, Dropdown, Layout, Menu, MenuProps, Space } from 'antd';
import { intersection, isEmpty } from 'lodash';
import React, { Suspense, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { useDispatch } from 'react-redux';
import { Link, Navigate, Outlet } from 'react-router-dom';
import styled from 'styled-components';

import { MQTT_URL, TITLE } from 'src/config';
import { logoutSlice, StationStateWatcher, useSession } from 'src/features';
import { MqttProvider } from 'src/lib/mqtt';
import { useSlice } from 'src/lib/redux-toolkit';
import { RoutePathDefinition, useActiveRoutePaths } from 'src/lib/routing';
import { navRoutes } from 'src/routes';

const { Sider, Header } = Layout;

function getHomePath(items: any[] | undefined): string {
  if (!items) return 'nohome';
  const [first] = items!;
  if (!first) return 'nohome';
  if (first.children) return getHomePath(first.children);
  return first.key;
}

export function Main() {
  const [collapsed, setCollapsed] = useState(false);
  // const navigate = useNavigate();
  const session = useSession();
  const dispatch = useDispatch();
  const activeRoutePaths = useActiveRoutePaths(navRoutes);

  const { privileges = [] } = session || {};
  const currentPath = activeRoutePaths.map((r) => r.definition.path).join('/');
  const currentTitle = activeRoutePaths.map((r, i) => r.title + '-戎达充换电运营管理平台');
  const [, logout] = useSlice(logoutSlice);
  const navItems = (rts: RoutePathDefinition[], parentPath: string = ''): MenuProps['items'] => {
    const items: MenuProps['items'] = [];
    for (const route of rts) {
      const { title = '', path = '', children, icon, hidden, privileges: ps } = route;
      if (hidden) continue;

      const key = parentPath ? [parentPath, path].join('/') : path;
      const navChildren = children && navItems(children, path);
      const label = navChildren ? (title as string) : <Link to={key}>{title as string}</Link>;

      if (!isEmpty(privileges) && isEmpty(intersection(privileges, ps)) && isEmpty(navChildren)) continue;

      items.push({
        label,
        key,
        children: navChildren,
        icon,
      });
    }

    return items;
  };

  const navMenuItems = navItems(navRoutes);
  const onLogout = async () => {
    dispatch(logout.request({ session: session!.id }));
  };

  const menuItems = [
    {
      key: 'logout',
      icon: <LogoutOutlined />,
      label: (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a target="_blank" rel="noopener noreferrer" onClick={onLogout} onKeyDown={onLogout}>
          退出登录
        </a>
      ),
    },
  ];

  const menu = <Menu items={menuItems} />;
  const marginLeft = collapsed ? 80 : 256;

  return (
    <DocumentTitle title={`${currentTitle[currentTitle.length - 1]}`}>
      <StyledContainer>
        {!currentPath && <Navigate to={getHomePath(navMenuItems)} replace />}
        <Layout hasSider style={{ minWidth: '1420px', overflowX: 'auto' }}>
          <Sider
            className="sider"
            trigger={null}
            collapsible
            collapsed={collapsed}
            width={256}
            style={{
              overflow: 'hidden',
              position: 'fixed',
              left: 0,
              top: 0,
              bottom: 0,
              zIndex: 1000,
            }}
          >
            <Logo>
              <Image />
              {!collapsed && <span> &nbsp; {TITLE}</span>}
            </Logo>

            <StyledMenu
              theme="dark"
              mode="inline"
              items={navMenuItems}
              selectedKeys={[currentPath]}
              defaultOpenKeys={
                activeRoutePaths.length > 1 ? [activeRoutePaths[0].definition.path as string] : undefined
              }
              // onClick={({ key }) => navigate(key)}
            />
          </Sider>

          <Layout style={{ marginLeft }} className="header">
            <StyledHeader>
              <div style={{ display: 'flex', height: '100%', alignItems: 'center' }}>
                {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                  className: 'trigger',
                  onClick: () => setCollapsed(!collapsed),
                })}
                <Breadcrumb style={{ marginLeft: 24 }}>
                  {activeRoutePaths.map((active, index) => {
                    return <Breadcrumb.Item key={index}>{active.title}</Breadcrumb.Item>;
                  })}
                </Breadcrumb>
              </div>
              <div className="header-right">
                <Dropdown overlay={menu}>
                  <Space style={{ paddingLeft: 24 }}>
                    <Avatar size={24} icon={<UserOutlined />} />
                    <span style={{ color: '#333' }}>
                      {session?.user?.nickname || session?.user?.username}
                      <DownOutlined style={{ marginLeft: 12, color: '#999' }} />
                    </span>
                  </Space>
                </Dropdown>
              </div>
            </StyledHeader>

            <Suspense fallback={<div>Loading...</div>}>
              {session?.token ? (
                <MqttProvider
                  url={MQTT_URL}
                  options={{
                    username: 'web',
                    password: session?.token,
                  }}
                >
                  <StationStateWatcher />
                  <Outlet />
                </MqttProvider>
              ) : (
                <Outlet />
              )}
            </Suspense>
          </Layout>
        </Layout>
      </StyledContainer>
    </DocumentTitle>
  );
}

const StyledContainer = styled.div`
  @media print {
    * {
      -webkit-print-color-adjust: exact;
      -moz-print-color-adjust: exact;
      -ms-print-color-adjust: exact;
      print-color-adjust: exact;
    }

    .sider,
    header {
      display: none;
    }
  }
`;

const Logo = styled.div`
  height: 48px;
  background: #00284d;
  color: white;
  line-height: 48px;
  padding: 0 16px;
  text-align: center;
`;

const Image = styled.img.attrs({ src: '/images/logo-small.png' })`
  display: inline;
  width: 32px;
  height: 32px;
  vertical-align: middle;
`;

const StyledHeader = styled(Header)`
  position: fixed;
  z-index: 100;
  width: calc(100vw - 256px);
  padding: 0 12px;
  height: 48px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px;

  .header-right {
    display: flex;
    float: right;
    align-items: center;

    .ant-avatar-icon {
      margin: 0 8px 0 24px;
    }

    .ant-badge,
    .ant-dropdown-trigger {
      cursor: pointer;
    }
  }
`;

const StyledMenu = styled(Menu)`
  height: calc(100vh - 60px);
  border-right: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  margin-top: 12px;

  &:hover {
    overflow-y: auto;
  }

  /* 设置滚动条的样式 */
  &::-webkit-scrollbar {
    width: 4px;
    height: 0;
  }
  /* 滚动槽 */
  &::-webkit-scrollbar-track {
    box-shadow: #818585;
    -webkit-box-shadow: #818585;
    border-radius: 1px;
  }
  /* 滚动条滑块 */
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #818585;
    box-shadow: #818585;
    -webkit-box-shadow: #818585;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: #818585;
  }
`;
