/**
 * init dotenv
 *
 * .env: Default.
 * .env.local: Local overrides. This file is loaded for all environments except test.
 * .env.development, .env.test, .env.production: Environment-specific settings.
 * .env.development.local, .env.test.local, .env.production.local: Local overrides of environment-specific settings.
 *
 * Available settings from baseimage
 *
 * REACT_APP_TITLE='React App'
 */

/**
 *
 * @param {string} name envrionment name
 * @param {string} init default value
 * @returns {string} value
 */
function env(name: string, init: string): string {
  const key = `REACT_APP_${name.toUpperCase()}`;
  const buildtimeValue = process.env?.[key];

  //@ts-ignore
  const runtimeValue = window._runtime_?.[key];
  const value = runtimeValue || buildtimeValue || init;

  if (value === undefined) {
    throw new Error(`environment ${name} is missing`);
  }

  return value;
}

/**
 * chore
 */
export const ECHO = env('ECHO', 'true').toLocaleLowerCase() === 'true';
export const VERSION = env('VERSION', '0.0.0');
export const BUILD = env('BUILD', '20220627-000000');
export const COPYRIGHT = env('COPYRIGHT', '36node 出品');

/**
 * app
 */
export const TITLE = env('TITLE', '戎达充换电运营管理平台');
export const ROOT_NS = env('ROOT_NS', 'mekong');

/**
 * api
 */
export const STARGATE_ENDPOINT = env('STARGATE_ENDPOINT', '/api');
export const STARGATE_PROVIDER = env('STARGATE_PROVIDER', '62efa6186c171b00128066c0');
export const STARGATE_SCOPE = env('STARGATE_SCOPE', 'mekong');
export const MEKONG_ENDPOINT = env('MEKONG_ENDPOINT', '/api');
export const API_CACHE_TIME = parseInt(env('API_CACHE_TIME', `${10 * 60 * 1000}`)); // ms

/**
 * login
 */
// 登录失败后锁定的时间，单位为秒
export const LOGIN_FAIL_LOCK_SECONDS = parseInt(env('LOGIN_FAIL_LOCK_SECONDS', `${5 * 60}`), 10);

/**
 * oss
 */

export const OSS_DOMAIN = env('OSS_DOMAIN', 'https://36node-mekong-uat.oss-cn-shanghai.aliyuncs.com');
export const OSS_ENDPOINT = env('OSS_ENDPOINT', 'https://oss-cn-shanghai.aliyuncs.com');
export const FILE_BUCKET = env('FILE_BUCKET', '36node-mekong-uat');
export const ASSCESS_KEY_ID = env('ASSCESS_KEY_ID', 'LTAI5tQUtwXdm6mKz9iyZiAP');
export const ASSCESS_KEY_SECRET = env('ASSCESS_KEY_SECRET', 'ctg5BHAAKltbYf666q0z4HS2SFxXx0');
export const IMAGE_DIR = env('IMAGE_DIR', 'rd');
export const OSS_URL = env('OSS_URL', 'https://oss.mekong-uat.36node.com');

/**
 * mqtt
 */
export const MQTT_URL = env('MQTT_URL', 'wss://mqtt.baowu.36node.com:8084/mqtt');
export const MQTT_CONSUMER_GROUP = env('MQTT_CONSUMER_GROUP', 'baowu');

/**
 * video
 */

export const VIDEO_HOST = env('VEDIO_HOST', 'https://video.enneagon.com');

// 高德地图 key
export const AMAP_KEY = env('AMAP_KEY', '76bc843ad59ac45ccd1e20b2a79f4694');
// 高德地图样式
export const AMAP_STYLE = env('AMAP_STYLE', 'amap://styles/14ae2934af01871a240b06db5f4df292');

/**
 * monitor
 */

// 监控页右侧卡口最大数量
export const WARNING_CARD_MAX_SIZE = parseInt(env('WARNING_CARD_MAX_SIZE', '5'), 10);
export const BBOX_OFFLINE_MS = parseInt(env('BBOX_OFFLINE_MS', `${2 * 24 * 60 * 60 * 1000}`), 10); // 2 天
