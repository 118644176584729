import { Spin } from 'antd';
import { isArray, isEmpty } from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { useSlice } from 'src/lib/redux-toolkit';
import * as services from 'src/services';

import { refreshSlice, sessionSlice } from './slice';

interface SessionProviderProp {
  children: JSX.Element;
}

interface RequireAuthProp {
  children: JSX.Element;
  privileges?: string[];
  roles?: string[];
  redirect?: string;
}

type WithAuthFcProp = {
  isAuth: boolean;
};

interface WithAuthProp {
  children: JSX.Element | React.FC<WithAuthFcProp>;
  privileges?: string[];
}

const TOKEN_THRESHOLD = 10; // 在 token 过期前多少分钟

export const useLoginUser = () => services.getSession()?.user;
export const useSession = () => services.getSession();

const Container = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  line-height: 80vh;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
`;

function isAuthorized(src: string[] = [], required: string | string[] | undefined): boolean {
  if (isEmpty(required)) return true; // 没有需要的权限，则直接返回 true
  if (isArray(required)) {
    return required.some((p) => src.includes(p)); // 如果需要的权限是数组，则检查是否包含其中一个
  }
  return src.includes(required as string);
}

/**
 * session 上下文提供者
 *
 * - 自动登录
 * - 自动刷新 token
 */
export const SessionProvider = ({ children }: SessionProviderProp) => {
  const dispatch = useDispatch();
  const sessionId = services.getSessionId();
  useSlice(sessionSlice);
  const [{ error }, refresh] = useSlice(refreshSlice);

  useEffect(() => {
    // 存在 sessionId 自动登录
    if (sessionId) {
      dispatch(refresh.request({ session: sessionId }));
    }

    // 定期刷新 token
    const interval = setInterval(() => {
      // 已经有 session，并且 token 还有效
      const session = services.getSession();
      if (session && moment(session.tokenExpireAt) < moment().add(TOKEN_THRESHOLD, 'minutes')) {
        dispatch(refresh.request({ session: session.id }));
      }
    }, 2 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  if (error) {
    services.removeSessionId();
    services.removeSession();
    window.location.href = '/login';
  }

  if (sessionId && !services.getSession()) {
    return (
      <Container>
        <Spin />
      </Container>
    );
  }

  return children;
};

export const RequireAuth = ({ children, roles = [], privileges = [], redirect = '/login' }: RequireAuthProp) => {
  const session = useSession();
  const location = useLocation();

  if (!session) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={redirect} state={{ from: location }} replace />;
  }
  if (!isAuthorized(session.privileges, privileges)) {
    return <Navigate to="/403" state={{ from: location }} replace />;
  }

  return children;
};

export const WithAuth = ({ children, privileges = [] }: WithAuthProp) => {
  const session = useSession();
  const isAuth = isAuthorized(session?.privileges, privileges);

  if (typeof children === 'function') {
    return children({ isAuth });
  }

  return isAuth ? children : null;
};
