/**
 * 整个系统的用户类型分为三种
 */
export enum UserType {
  MAINTAINER = 'MAINTAINER',
  DRIVER = 'DRIVER',
  SYS = 'SYS',
}

export const sheetLabels = {
  STATION_WORK_ORDER: 'work-order:station',
  BATTERY_WORK_ORDER: 'work-order:battery',
};

export const BuiltInRoles = ['USERMANAGER', 'USER'];
